import { render, staticRenderFns } from "./page.vue?vue&type=template&id=795168b5&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"
import style0 from "./page.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DashboardPollsPanel from '@/components/dashboard/polls_panel.vue'
import Loading from '@/components/common/loading.vue'
import LoadingContent from '@/components/common/loading_content.vue'
import ThreadPreview from '@/components/thread/preview.vue'
import ThreadSearchResult from '@/components/thread/search_result.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {DashboardPollsPanel,Loading,LoadingContent,ThreadPreview,ThreadSearchResult,VCard,VContainer,VLayout,VList,VMain,VSubheader,VTextField})
