
import Session        from '@/shared/services/session'
import StanceService        from '@/shared/services/stance_service'
import AbilityService from '@/shared/services/ability_service'
import openModal from '@/shared/helpers/open_modal'

export default
  props:
    event: Object
    eventable: Object
    collapsed: Boolean

  computed:
    poll: -> @eventable.poll()
    showResults: -> @poll.showResults()
    actions: -> StanceService.actions(@eventable)

    componentType:  ->
      if @event.actor()
        'router-link'
      else
        'div'
